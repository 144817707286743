import { Component } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { interval } from 'rxjs';
import { faLink } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrl: './earnings.component.scss'
})
export class EarningsComponent {
  /**
   * Displays spinner when waiting for payments to be fetched from backend
   */
  isLoading: boolean;
  totalPayments: Number = 0;
  pendingPayments: Number = 0;
  totalEarnings: Number = 0;
  pendingEarnings: Number = 0;
  countdown: string = '';
  nextTargetDate: string = '';
  linkIcon = faLink;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for redirection
   */
  constructor(
    private api: ApiService,
    private notify: NotificationService,
  ) {}

  /**
   * Fetches jobs when the component is initialized
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.fetchTransactions();
    this.calculateCountdown();

    // Update the countdown every second
    interval(1000).subscribe(() => {
      this.calculateCountdown();
    });
  }

  // Function to calculate the next countdown target (15th or 28th)
  getNextTargetDate(): Date {
    const now = new Date();
    const currentDay = now.getDate();
    let targetDay: number;

    if (currentDay >= 28 || currentDay < 15) {
      targetDay = 15; // Countdown to the 15th
    } else {
      targetDay = 28; // Countdown to the 28th
    }

    let targetDate = new Date(now.getFullYear(), now.getMonth(), targetDay, 23, 59, 59);

    // Adjust for next month if the current day is >= 28 and we're counting to the 15th
    if (currentDay >= 28) {
      targetDate.setMonth(targetDate.getMonth() + 1);
    }

    return targetDate;
  }

  // Function to calculate the time difference and format it as a countdown string
  calculateCountdown(): void {
    const now = new Date();
    const targetDate = this.getNextTargetDate();
    const timeDiff = targetDate.getTime() - now.getTime();

    // Store the next target date as a formatted string for display
    this.nextTargetDate = targetDate.toDateString();

    if (timeDiff > 0) {
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else {
      this.countdown = 'Time is up!';
    }
  }


  fetchTransactions() {
    this.isLoading = true;
    this.api.get('/transactions/freelancer-stats').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.pendingPayments = res.pending_payments;
          this.totalPayments = res.received_payments;
          this.pendingEarnings = res.pending_earnings;
          this.totalEarnings = res.approved_earnings;
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        console.error(e);
      }
    }); 
  }
}
