import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotificationService } from '../../../core/services/notification.service';
import { User } from '../../../shared/Interfaces/User';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.scss'
})
export class EditProfileComponent implements OnInit{
  /**
   * Form for editing user profile details
   */
  editProfileForm: FormGroup;
  /**
   * Used for form validation and displaying validation errors
   */
  submitted = false;
  /**
   * Used to display spinner when waiting for the profile picture to be uploaded
   */
  userLoading: boolean = true;
  /**
   * Used to display spinner when waiting for the profile picture to be uploaded
   */
  loading: boolean;
  /**
   * Used to display spinner when waiting for the profile picture to be removed
   */
  deleteLoading: boolean;
  /**
   * Used to display spinner when waiting for the user's profile details to be updated
   */
  isLoading: boolean;
  /**
   * The logged in user
   */
  user: User;
  /**
   * Variable to store selected days
   */
  selectedCategories: string[] = [];
  /**
   * The services being offered
   */
  services = [
    {
      title: 'Digital Marketing',
      selected: false
    },
    {
      title: 'Data Science',
      selected: false
    },
    {
      title: 'Software Development',
      selected: false
    },
    {
      title: 'Graphic Design',
      selected: false
    },
    {
      title: 'UI UX',
      selected: false
    },
    {
      title: 'Video Editing',
      selected: false
    },
    {
      title: 'Transcription',
      selected: false
    },
    {
      title: 'Copywriting',
      selected: false
    },
    {
      title: 'Accounting',
      selected: false
    },
    {
      title: 'AI Services',
      selected: false
    },
  ]
  selectedCount: number = 0;
  /**
   * Displayed on the upload resume input
   */
  uploadIcon = faCloudArrowUp;
  /**
   * Used to display spinner when uploading resume
   */
  uploadingResume: boolean;
  /**
   * Used to display spinner when downloading resume
   */
  downloadingResume: boolean;
  characters: string = '';

  /**
   * Adds dependencies into the component.
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param authService - Authentication service
   * @param cd - Detects change
   */
  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private api: ApiService,
    private notify: NotificationService,
    private authService: AuthenticationService
  ) { }

  /**
   * Fetches the logged in user when the component is initialized
   * Initializes the editProfileForm with default values
   */
  ngOnInit(): void {
    this.fetchUser();
    this.editProfileForm = this.formBuilder.group({
      bio: ['', Validators.required],
      title: ['', Validators.required],
    });
  }

  /**
   * Gets all controls of the editProfileForm
   */
  get f(): FormGroup['controls'] {
    return this.editProfileForm.controls;
  }

  /**
   * Fetches the logged in user's details
   */
  fetchUser(): void {
    this.api.get('/users/' + this.authService.userID).subscribe(
      res => {
        if (res.status_code === 200) {
          this.user = res.detail;
          this.characters = res.detail.bio;
          this.editProfileForm.get('title')?.setValue(res.detail.title);
          this.updateServiceSelection();
          this.userLoading = false;
        } else {
          this.notify.showError(res.detail);
          this.userLoading = false;
        }
      }
    );
  }

  /**
   * Updates the selection status of services based on user's list of skills.
   * If a service is found in the selected services list, its 'selected' property is set to true,
   * otherwise, it's set to false. Also updates the selectedCount.
   */
  updateServiceSelection(): void {
    this.selectedCount = 0; // Reset the count
    this.services.forEach(service => {
      if (this.user.skills.includes(service.title)) {
        service.selected = true;
        this.selectedCount++; // Increment the count for each selected service
      } else {
        service.selected = false;
      }
    });
  }

  /**
   * Updates the user's profile information
   */
  update(): void {
    this.submitted = true;

    if (this.editProfileForm.invalid) {
      return;
    }

    this.isLoading = true;

    this.updateSelectedSkills();

    const info = {
      user_id: this.authService.userID,
      bio: this.editProfileForm.get('bio')?.value,
      title: this.editProfileForm.get('title')?.value,
      skills: this.selectedCategories
    };

    this.api.put('/users/set-infos', JSON.parse(JSON.stringify(info))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.isLoading = false;
          this.notify.showSuccess(res.detail);
        } else {
          this.notify.showError(res.detail);
          this.isLoading = false;
        }
      }
    );
  }

  /**
   * Removes the user's profile picture
   */
  removeProfilePicture(): void {
    this.deleteLoading = true;
    this.api.deleteNoBody('/users/delete-profile-picture/' + this.user._id).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.notify.showSuccess('Profile picture successfuly removed');
          this.deleteLoading = false;
          this.fetchUser();
        } else {
          this.notify.showError(res.detail);
          this.deleteLoading = false;
        }
      },
      error: (e) => {
        this.notify.showError(e);
        this.deleteLoading = false;
      } 
    });
  }

  uploadResume(event: any): void {
    const file = event.target.files[0];

    if (file.size / 1000000 > 5 && event.target.files && event.target.files[0]) {
      this.notify.showError('The file must be less than 5MB');
    }

    if (file.size / 1000000 < 5 && event.target.files && event.target.files[0]) {
      this.uploadingResume = true;
      const formData = new FormData();
      formData.append('file', file);

      this.api.postFormData('/users/upload-resume', formData).subscribe({
        next: res => {
          if (res.status_code === 200) {
            this.notify.showSuccess('Resume uploaded successfully');
            this.uploadingResume = false;
            this.fetchUser();
          } else {
            this.notify.showError(res.detail);
            this.uploadingResume = false; 
          }
        },
        error: (e) => {
          console.error(e);
          this.uploadingResume = false;
        }
      })
    }
  }

  downloadResume(resume: string): void {
    this.downloadingResume = true;
    this.api.getFile('/users/download-resume/' + this.user._id).subscribe(
      blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = resume; // Replace with your filename and extension
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.downloadingResume = false;
      }
    )
  }

  /**
   * Uploads a profile picture
   * @param event - Object with the file to be uploaded
   */
  uploadFile(event: any): void {
    const reader = new FileReader(); // HTML5 FileReader API
    const file = event.target.files[0];

    if (file.size / 1000000 > 5 && event.target.files && event.target.files[0]) {
      this.notify.showError('The image must be less than 5MB');
    }

    if (file.size / 1000000 < 5 && event.target.files && event.target.files[0]) {
      this.loading = true;
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.editProfileForm.patchValue({
          file: reader.result
        });
        const formData = new FormData();
        formData.append('image', file);
        this.api.postFormData('/users/set-profile-picture/' + this.authService.userID, formData).subscribe({
          next: res => {
            if (res.status_code === 200) {
              this.notify.showSuccess('Profile picture set successfully.');
              this.loading = false;
              this.fetchUser();
            } else {
              this.notify.showError(res.detail);
              this.loading = false;
            }

          },
          error: (e) => {
            this.loading = false;
            this.notify.showError('Profile picture was not set successfully.');
          }
        });
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }


  /**
   * Function to handle category selection.
   * 
   * @param category - The category object representing the category to toggle selection for.
   */
  toggleCategorySelection(category: any): void {
    if (category.selected) {
      // If the category is already selected, unselect it
      category.selected = false;
      this.selectedCount--;
    } else if (this.selectedCount < 3) {
      // If the category is not selected and we haven't reached the limit, select it
      category.selected = true;
      this.selectedCount++;
    } else {
      // If we've reached the limit, don't allow further selections
      return;
    }
    this.updateSelectedSkills();
  }

  /**
   * Reset the categories selection to false
   */
  resetCategorysSelection() {
    this.services = [
      {
        title: 'Digital Marketing',
        selected: false
      },
      {
        title: 'Data Science',
        selected: false
      },
      {
        title: 'Software Development',
        selected: false
      },
      {
        title: 'Graphic Design',
        selected: false
      },
      {
        title: 'UI UX',
        selected: false
      },
      {
        title: 'Video Editing',
        selected: false
      },
      {
        title: 'Transcription',
        selected: false
      },
      {
        title: 'Copywriting',
        selected: false
      },
      {
        title: 'Accounting',
        selected: false
      },
      {
        title: 'AI Services',
        selected: false
      },
    ];
    this.selectedCount = 0; // Reset the selected count
  }

  /** 
   * Function to update selected skills variable
   */
  private updateSelectedSkills(): void {
    this.selectedCategories = this.services.filter(service => service.selected).map(service => service.title);
  }
}
