import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Job } from '../../../shared/Interfaces/Job';
import { Router } from '@angular/router';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrl: './jobs.component.scss'
})
export class JobsComponent implements OnInit{
  /**
   * Displays spinner when waiting for data to be fetched from backend
   */
  isLoading = true;
  /**
   * Variable to store the freelancer's jobs
   */
  jobs: Job[] = [];
  /**
   * Used to check if there are any jobs
   */
  jobsAvailable: boolean;

  jobsPagingConfig: PagingConfig = {} as PagingConfig;
  constructor(
    private api: ApiService,
    private notify: NotificationService,
    private router: Router
  ) {}

  /**
   * Fetches jobs when the component is initialized
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.jobsAvailable = false;
    this.fetchJobs();
    this.jobsPagingConfig = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: 0
    }
  }

  fetchJobs() {
    this.isLoading = true;
    this.api.get('/jobs/get-freelancer-jobs').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.jobsPagingConfig.totalItems = res.detail.length;
          if (res.detail.length > 0) {
            this.jobsAvailable = true;
            this.jobs = res.detail;
          } 
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        console.error(e);
      }
    }); 
  }

  /**
   * Detects if the data in the jobs has changed
   * @param page - The page that has been selected
   */
  onjobsDataChange(event: any): void{
    this.jobsPagingConfig.currentPage = event;
    this.fetchJobs();
  }
}
