<div class="container">
    <div class="d-flex justify-content-center" *ngIf="loading">
        <app-loading></app-loading>
    </div>
    <div *ngIf="!loading && ID" class="action">
        <button class="btn btn-info mb-3" (click)="apply(job._id)" [disabled]="applyLoading"
            *ngIf="!job.userApplied">APPLY
            <span *ngIf="applyLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
            </span>
        </button>
        <p class="applied" *ngIf="job.userApplied">ALREADY APPLIED</p>
    </div>
    <div class="card top mb-3" *ngIf="!loading && ID">
        <h2 class="card-title">{{job.title}}</h2>
        <p class="card-subtitle mb-2 text-body-secondary">{{job.description}}</p>
        <p class="card-text mb-3"><strong>Skills: </strong>{{job.skills}}</p>
        <div class="row mb-3">
            <div class="col-md-2">
                <p class="card-text"><strong>Posted: </strong>{{job.created_on | date: 'mediumDate'}}</p>
            </div>
            <div class="col-md-2">
                <p class="card-text"><strong>Budget: </strong>{{(job.freelancer_amount).toFixed(2) }} USD</p>
            </div>
            <div class="col-md-2">
                <p class="card-text"><strong>Percent Paid: </strong>{{(job.amountPaid/job.budget)*100}}%</p>
            </div>
            <div class="col-md-2">
                <p class="card-text"><strong>Duration: </strong>{{job.duration}}</p>
            </div>
            <div class="col-md-2">
                <p class="card-text"><strong>Milestones: </strong>{{job.milestones.length}}</p>
            </div>
            <div class="col-md-2">
                <p class="card-text"><strong>Status: </strong>{{job.status}}</p>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-2">
                <p class="card-text"><strong>Deadline: </strong>{{job.deadline | date: 'mediumDate'}}</p>
            </div>
            <div class="col-md-2">
                <p class="card-text applied" *ngIf="job.freelancer">Applied On: {{job.freelancer.applied_on | date:
                    'mediumDate'}}</p>
            </div>
            <div class="col-md-2">
                <p class="card-text applied" *ngIf="job.status === 'Completed'">Completed On: {{job.approved_on | date:
                    'mediumDate'}}</p>
            </div>
        </div>
        <h4 *ngIf="job.milestones.length > 0">Milestones</h4>
        <div *ngFor="let milestone of job.milestones" class="mb-3">
            <div class="row mb-2">
                <div class="col-md-2">
                    <p class="card-text"><strong>Duration: </strong>{{milestone.milestoneDuration}}
                        {{milestone.milestonePeriod}}(s)</p>
                </div>
                <div class="col-md-2">
                    <p class="card-text"><strong>Deadline: </strong>{{milestone.milestoneDeadline | date: 'mediumDate'}}
                    </p>
                </div>
                <div class="col-md-8 mb-2">
                    <p class="card-text"><strong>Description: </strong>{{milestone.milestoneDescription }}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!loading && allowWorkSubmission" class="submissions">
        <h3 class="mb-3">Submissions</h3>
        <button class="btn btn-info mb-3" (click)="displaySubmitWorkModal()" *ngIf="job.status == 'In Progress'">SUBMIT
            WORK</button>
        <p *ngIf="job.submissions.length == 0"><strong>Work that you submit will be displayed here.</strong></p>
        <div class="submissions" *ngIf="job.submissions.length > 0">
            <div class="card mb-2"
                *ngFor="let submission of job.submissions | paginate : submissionsPagingConfig; let i = index">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <p><strong>Submitted On: </strong>{{submission.submitted_on | date: 'mediumDate'}}</p>
                        </div>
                        <div class="col-md-4">
                            <p><strong>URL: </strong><a href={{submission.link}} target="_blank">Link to Submitted Work</a></p>
                        </div>
                        <div class="col-md-4">
                            <p><strong>Status: </strong>{{submission.status | titlecase}}</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <p><strong>Comment: </strong>{{ submission.comment }}</p>
                        </div>
                        <div class="col-md-4" *ngIf="submission.feedback">
                            <p><strong>Feedback: </strong>{{ submission.feedback }}</p>
                        </div>
                    </div>
                    <div class="file" *ngIf="submission.file">
                        <button class="btn btn-info" (click)="downloadFile(submission.file)"
                            [disabled]="downloadingFileMap[submission.file]">
                            DOWNLOAD FILE
                            <span *ngIf="downloadingFileMap[submission.file]" class="spinner-border spinner-border-sm"
                                role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <pagination-controls previousLabel="Prev" nextLabel="Next"
                    (pageChange)="onSubmissionsDataChange($event)">
                </pagination-controls>
            </div>
        </div>
    </div>

    <!-- SUBMIT WORK MODAL -->
    <ng-template #submitWorkModal let-close="close">
        <div class="modal-header">
            <h5 class="modal-title">Submit Work</h5>
        </div>
        <div class="modal-body">
            <form [formGroup]="submitWorkForm">
                <div class="form-group mb-3">
                    <label for="link">Link</label>
                    <input type="text" class="form-control" formControlName="link"
                        [ngClass]="{ 'is-invalid': submitted && f['link'].errors}">
                    <div *ngIf="submitted && f['link'].errors" class="invalid-feedback">
                        <div *ngIf="f['link']['errors']required">Link is required</div>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="comment">Comment</label>
                    <textarea class="form-control" class="form-control" formControlName="comment" rows="4"
                        [ngClass]="{ 'is-invalid': submitted && f['comment'].errors}"></textarea>
                    <div *ngIf="submitted && f['comment'].errors" class="invalid-feedback">
                        <div *ngIf="f['comment']['errors']required">Comment is required</div>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <p class="filename" *ngIf="fileName">Selected File: {{fileName}}</p>
                    <input type="file" id="fileUpload" accept=".pdf" #fileUpload (change)="selectFile($event)" />
                    <button class="d-flex btn btn-info btn-block" (click)="fileUpload.click()">
                        SELECT FILE TO UPLOAD
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="btn btn-info" style="margin-right: 20px;" (click)="close()">CLOSE</button>
            <button class="btn btn-info" (click)="submitWork()" [disabled]="submitWorkLoading">SUBMIT WORK
                <span *ngIf="submitWorkLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
            </button>
        </div>
    </ng-template>
</div>