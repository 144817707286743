<div class="container">
    <div class="row countdown">
        <div class="col-md-12 text-center">
            <h4 class="mb-3"><a [href]="'/payment-terms'" target="_blank">Payment Terms <fa-icon [icon]="linkIcon"></fa-icon></a></h4>
            <h3>Countdown to the next payment date: <strong>{{nextTargetDate}}</strong></h3>
            <h2>{{ countdown }}</h2>
        </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="isLoading">
        <app-loading></app-loading>
    </div>
    <hr/>
    <div class="row" *ngIf="!isLoading">
        <div class="col-md-3 mb-2">
            <h3 class="text-center">Pending Earnings</h3>
            <h6 class="mb-5">Pending Earnings are calculated based on the jobs that you are working on that are still in progress. This includes all active jobs where work is being done but the client has not yet marked the project as completed.</h6>
            <p>{{(pendingEarnings).toFixed(2)}} USD</p>
        </div>
        <div class="col-md-3 mb-2">
            <h3 class="text-center">Total Earnings</h3>
            <h6 class="mb-5">Total Earnings are calculated based on the jobs that you have worked on and are marked as complete by the client. This reflects your success on the platform and can be useful for tracking your freelance income over time.</h6>
            <p>{{(totalEarnings).toFixed(2)}} USD</p>
        </div>
        <div class="col-md-3 mb-2">
            <h3 class="text-center">Pending Payments</h3>
            <h6 class="mb-5">Pending Payments are calculated based on the jobs that you have completed but you haven't received payment for. This amount will be sent to you on or before the next payment date shown above but not later than that.</h6>
            <p>{{(pendingPayments).toFixed(2)}} USD</p>
        </div>
        <div class="col-md-3 mb-2">
            <h3 class="text-center">Total Payments</h3>
            <h6 class="mb-5">Total Payments are calculated based on the jobs that you have worked on and are marked as complete by the client. This is the actual income that has been transferred to your bank account or chosen payment method.</h6>
            <p>{{(totalPayments).toFixed(2)}} USD</p>
        </div>
    </div>
</div>