import { Component } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Job } from '../../../shared/Interfaces/Job';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { User } from '../../../shared/Interfaces/User';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';
import { Router } from '@angular/router';

@Component({
  selector: 'app-find-work',
  templateUrl: './find-work.component.html',
  styleUrl: './find-work.component.scss'
})
export class FindWorkComponent {
  /**
   * Used to check if there are any jobs
   */
  jobsAvailable: boolean;
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  isLoading = true;
  /**
   * Displays spinner when appllying for a job
   */
  applyLoading: boolean;
  /**
   * Variable to store the client's jobs
   */
  jobs: Job[] = [];
  /**
   * The logged in user
   */
  user: User;
  /**
   * Used to display spinner when waiting for the user to be fetched from the backend
   */
  userLoading: boolean;
  /**
   * Used to check if the user has completed their profile
   */
  profileIncomplete: boolean;

  jobsPagingConfig: PagingConfig = {} as PagingConfig;
  constructor(
    private api: ApiService,
    private notify: NotificationService,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  /**
   * Fetches jobs when the component is initialized
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.jobsAvailable = false;
    this.fetchJobs();
    this.userLoading = true;
    this.fetchUser();
    this.jobsPagingConfig = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: 0
    }
  }

  fetchJobs() {
    this.isLoading = true;
    this.api.get('/jobs/open').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.jobsPagingConfig.totalItems = res.detail.length;
          if (res.detail.length > 0) {
            this.jobsAvailable = true;
            this.jobs = res.detail;
            this.addApplicationStatusToJobs(this.jobs);
          } 
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        console.error(e);
      }
    }); 
  }

  /**
   * Fetches the logged in user's details
   */
  fetchUser(): void {
    this.api.get('/users/' + this.authService.userID).subscribe(
      res => {
        if (res.status_code === 200) {
          this.user = res.detail;
          if (!this.user.bio || !this.user.title || !this.user.resume || this.user.skills.length == 0 || this.user.profile_image == '') {
            this.profileIncomplete = true;
          } else {
            this.profileIncomplete = false;
          }
          this.userLoading = false;
        } else {
          this.notify.showError(res.detail);
          this.userLoading = false;
        }
      }
    );
  }

  // Method to add a property indicating whether the logged-in user has applied to each job
  addApplicationStatusToJobs(jobs: Job[]): void {
    const loggedInUser = this.authService.userID;
    jobs.forEach(job => {
      job['userApplied'] = job.applicants.some(applicant => applicant._id === loggedInUser);
    });
  }

  apply(job_id: string): void {
    this.applyLoading = true;

    const application = {
      user_id: this.authService.userID,
      job_id: job_id
    }

    this.api.put('/jobs/apply', JSON.parse(JSON.stringify(application))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.fetchJobs();
        } else {
          this.notify.showError(res.detail);
        }
        this.applyLoading = false;
        
      },
      error: (e) => {
        this.applyLoading = false;
        console.error(e);
      }
    })
  }

  /**
   * Detects if the data in the jobs has changed
   * @param page - The page that has been selected
   */
  onjobsDataChange(event: any): void{
    this.jobsPagingConfig.currentPage = event;
    this.fetchJobs();
  }

}
