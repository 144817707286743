import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotificationService } from '../../../core/services/notification.service';
import { User } from '../../../shared/Interfaces/User';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit{
  /**
   * Form for editing user profile details
   */
  editProfileForm: FormGroup;
  /**
   * Stores the currently logged in user's details
   */
  user: User;
  /**
   * Used to display or hide the remove profile picture button
   * When true button is displayed, when false it is not displayed
   */
  removeUpload = false;
  /**
   * Used to display spinner when waiting for the profile picture to be removed
   */
  deleteLoading: boolean;
  /**
   * Used to display spinner when waiting for the user to be fetched from the backend
   */
  userLoading: boolean;
  /**
   * Used to display spinner when uploading resume
   */
  downloadingResume: boolean;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param authService - Authentication service
   */
  constructor(
    private api: ApiService,
    private authService: AuthenticationService,
    private notify: NotificationService,
  ) { }

  /**
   * Fetches the user's details
   */
  ngOnInit(): void {
    this.fetchUser();
  }

  /**
   * Fetches the user's details and their profile
   */
  fetchUser(): void {
    this.userLoading = true;
    this.api.get('/users/' + this.authService.userID).subscribe(
      res => {
        if (res.status_code === 200) {
          this.user = res.detail;
        } else {
          this.notify.showError(res.detail);
        }
        this.userLoading = false;
      }
    );
  }

  downloadResume(resume: string): void {
    this.downloadingResume = true;
    this.api.getFile('/users/download-resume/' + this.user._id).subscribe(
      blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = resume; // Replace with your filename and extension
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.downloadingResume = false;
      }
    )
  }

  /**
   * Logs out the user
   */
  logout(): void {
    this.authService.logout();
  }


}
