import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FreelancerRoutingModule } from './freelancer-routing.module';
import { FindWorkComponent } from './components/find-work/find-work.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { SharedModule } from '../shared/shared.module';
import { ProfileComponent } from './components/profile/profile.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { FreelancerJobDetailsComponent } from './components/freelancer-job-details/freelancer-job-details.component';
import { EarningsComponent } from './components/earnings/earnings.component';


@NgModule({
  declarations: [
    FindWorkComponent,
    JobsComponent,
    ProfileComponent,
    EditProfileComponent,
    FreelancerJobDetailsComponent,
    EarningsComponent
  ],
  imports: [
    CommonModule,
    FreelancerRoutingModule,
    SharedModule
  ]
})
export class FreelancerModule { }
