<div class="container">
    <div class="action">
        <button type="button" class="btn btn-info mb-3" routerLink="/edit-profile" *ngIf="!userLoading">
            EDIT PROFILE
        </button>
    </div>
    <div class="d-flex justify-content-center" *ngIf="userLoading">
        <app-loading></app-loading>
    </div>
    <div class="profile" *ngIf="!userLoading">
        <div class="row">
            <div class="col-md-2">
                <div class="avatar">
                    <div class="avatar-preview">
                        <div class="image-preview" [style.backgroundImage]="'url('+ user.profile_image +')'"></div>
                    </div>
                </div>
                <div class="missing" *ngIf="user.profile_image == ''">
                    <div class="profile">Your Profile Picture is missing. Kindly click on "EDIT PROFILE" to update your Profile Picture.</div>
                </div>
            </div>
            <div class="col-md-10">
                <div class="row name">
                    <h1>{{user.firstname}} {{user.lastname}}</h1>
                    <h3>{{user.title}}</h3>
                    <div class="missing mb-3" *ngIf="!user.title">
                        <div>Your Title is missing. Kindly click on "EDIT PROFILE" to update your title.</div>
                    </div>
                    <ngb-rating [max]="5" [(rate)]="user.average_rating" [readonly]="true"></ngb-rating>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <h3>Bio</h3>
            <p>{{user.bio}}</p>
            <div class="missing mb-3" *ngIf="!user.bio">
                <div>Your Bio is missing. Kindly click on "EDIT PROFILE" to update your bio.</div>
            </div>
        </div>
        <div class="mb-3">
            <h3>Skills</h3>
            <span class="badge rounded-pill text-bg-light" *ngFor="let skill of user.skills ">{{skill}}</span>
        </div>
        <div class="row">
            <div class="missing mb-3" *ngIf="user.skills.length == 0">
                <div>Your Skills are missing. Kindly click on "EDIT PROFILE" to update your skills.</div>
            </div>
        </div>
        <div class="resume mb-3">
            <h3>Resume</h3>
            <button class="btn btn-info" (click)="downloadResume(user.resume)" [disabled]="downloadingResume"
                *ngIf="user.resume">
                DOWNLOAD RESUME
                <span *ngIf="downloadingResume" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                </span>
            </button>
        </div>
        <div class="row missing mb-3" *ngIf="!user.resume">
            <div>Your Resume is missing. Kindly click on "EDIT PROFILE" to upload your resume.</div>
        </div>
        <div class="row reviews" *ngIf="user.reviews.length > 0">
            <h3>Reviews</h3>
            <div class="col-md-3" *ngFor="let review of user.reviews">
                <div class="card">
                    <div class="card-body">
                        <ngb-rating [max]="5" [(rate)]="review.rating" [readonly]="true"></ngb-rating>
                        <p class="card-text mb-3">{{review.comment}}</p>
                        <h6 class="card-subtitle mb-2 text-muted">{{review.firstname}} {{review.lastname}}</h6>
                        <h6 class="card-subtitle text-muted date">{{review.reviewed_on | date: 'mediumDate'}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>