import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FindWorkComponent } from './components/find-work/find-work.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { AuthGuard } from '../auth/auth-guard/auth-guard.guard';
import { Role } from '../shared/models/role.model';
import { ProfileComponent } from './components/profile/profile.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { FreelancerJobDetailsComponent } from './components/freelancer-job-details/freelancer-job-details.component';
import { EarningsComponent } from './components/earnings/earnings.component';

const routes: Routes = [
  { path: 'find-work', component : FindWorkComponent, canActivate: [AuthGuard], data: {roles: [Role.Freelancer]} },
  { path: 'jobs', component : JobsComponent, canActivate: [AuthGuard], data: {roles: [Role.Freelancer]} },
  { path: 'profile', component : ProfileComponent, canActivate: [AuthGuard], data: {roles: [Role.Freelancer]} },
  { path: 'edit-profile', component : EditProfileComponent, canActivate: [AuthGuard], data: {roles: [Role.Freelancer]} },
  { path: 'job/:ID', component : FreelancerJobDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.Freelancer]} },
  { path: 'earnings', component : EarningsComponent, canActivate: [AuthGuard], data: {roles: [Role.Freelancer]} }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FreelancerRoutingModule { }
