<div class="container">
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!isLoading && profileIncomplete &&!userLoading" class="incomplete mb-3">
    <div class="inner">
      <p>Your profile is incomplete. Kindly update your profile.</p>
      <button type="button" class="btn btn-info" routerLink="/profile">EDIT PROFILE
      </button>
    </div>
  </div>
  <div *ngIf="!jobsAvailable && !isLoading">
    <h2>UH OH!</h2>
    <p> There are no jobs that match your skills at the moment. Check Later.</p>
  </div>
  <div *ngIf="jobsAvailable && !isLoading">
    <h3>Jobs you might like</h3>
    <div class="card" *ngFor="let job of jobs | paginate : jobsPagingConfig; let i = index">
      <div class="card-body">
        <h5 class="card-title"><a [href]="'/job/' + job._id">{{job.title}}</a></h5>
        <p class="card-subtitle mb-2 text-body-secondary">{{job.description}}</p>
        <p class="card-text"><strong>Skills: </strong>{{job.skills}}</p>
        <div class="row mb-3">
          <div class="col-md-2">
            <p class="card-text"><strong>Posted: </strong>{{job.created_on | date: 'mediumDate'}}</p>
          </div>
          <div class="col-md-2">
            <p class="card-text"><strong>Budget: </strong>{{job.freelancer_amount}} USD</p>
          </div>
          <div class="col-md-2">
            <p class="card-text"><strong>Duration: </strong>{{job.duration}}</p>
          </div>
          <div class="col-md-2">
            <p class="card-text"><strong>Deadline: </strong>{{job.deadline | date: 'mediumDate'}}</p>
          </div>
          <div class="col-md-2">
            <p class="card-text"><strong>Applicants: </strong>{{job.applicants.length}}</p>
          </div>
        </div>
        <button type="button" class="btn btn-info mb-3" (click)="apply(job._id)" *ngIf="!job.userApplied && !profileIncomplete" [disabled]="applyLoading">APPLY
          <span *ngIf="applyLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
        <p class="applied" *ngIf="job.userApplied">ALREADY APPLIED</p>
      </div>
    </div>
    <div class="d-flex justify-content-center paginator">
      <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onjobsDataChange($event)">
      </pagination-controls>
    </div>
  </div>
</div>