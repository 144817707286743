import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Job } from '../../../shared/Interfaces/Job';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';

@Component({
  selector: 'app-freelancer-job-details',
  templateUrl: './freelancer-job-details.component.html',
  styleUrl: './freelancer-job-details.component.scss'
})
export class FreelancerJobDetailsComponent implements OnInit {
  /**
   * The job whose details are being displayed
   */
  job: Job;
  /**
   * Stores the ID received from the history state
   */
  ID: string;
  /**
   * Displays spinner when waiting for the job to be fetched from the backend
   */
  loading: boolean;
  /**
   * Displays spinner when applying for a job
   */
  applyLoading: boolean;
  /**
   * Form for submitting work
   */
  submitWorkForm: FormGroup;
  /**
   * Stores the state of the form for form validation and displaying validation errors
   */
  submitted = false;
  selectedFile: File | null = null;
  /**
   * Used to display spinner when uploading a file
   */
  uploadingFile: boolean;
  submissionsPagingConfig: PagingConfig = {} as PagingConfig;
  allowWorkSubmission: boolean = false;
  fileName: string | null = null;
  /**
   * Displays spinner when submitting work for a job
   */
  submitWorkLoading: boolean;
  /**
   * Stores the current page of the paginator
   */
  currentPage: number = 1;
  /**
   * Stores the count of the paginator
   */
  totalItems: number = 0;
  /**
   * Stores the total items displayed per page
   */
  itemsPerPage: number = 4;
  /**
   * Used to display spinner when downloading a file
   */
  downloadingFileMap: { [key: string]: boolean } = {};

  
  /**
   * Modal for submitting work for approval
   */
  @ViewChild('submitWorkModal', { static: true }) submitWorkModal: TemplateRef<any>;
  /**
   * Injects dependencies into the component
   */
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private notify: NotificationService,
    private formBuilder: FormBuilder,
    private modal: NgbModal,
  ) { }
  /**
   * Retrieves the ID of the job from the state variable
   */
  ngOnInit(): void {
    this.ID = this.route.snapshot.url[1].path;
    this.fetchJob();
    this.submitWorkForm = this.formBuilder.group({
      link: ['', Validators.required],
      comment: ['', Validators.required],
    });
    this.submissionsPagingConfig = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: 0
    }
  }

  /**
   * Getter for all the submitWorkForm controls
   */
  get f(): FormGroup['controls'] {
    return this.submitWorkForm.controls;
  }

  displaySubmitWorkModal(): void {
    this.modal.open(this.submitWorkModal, { size: 'md', centered: true });
  }

  /**
   * Fetches the job details from an API endpoint
   */
  fetchJob(): void {
    this.loading = true;
    if (this.ID) {
      this.api.get('/jobs/' + this.ID).subscribe({
        next: res => {
          if (res.status_code === 200) {
            const loggedInUser = this.authService.userID;
            this.job = res.detail;
            if (this.job.freelancer._id === loggedInUser) this.allowWorkSubmission = true;
            this.job['userApplied'] = this.job.applicants.some(applicant => applicant._id === loggedInUser);
          } else {
            this.notify.showError(res.detail);
            this.ID = '';
          }
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
          console.error(e);
        }
      });
    } else {
      this.notify.showError('Kindly provide a job ID');
      this.loading = false;
    }
  }

  apply(job_id: string): void {
    this.applyLoading = true;

    const application = {
      user_id: this.authService.userID,
      job_id: job_id
    }

    this.api.put('/jobs/apply', JSON.parse(JSON.stringify(application))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.notify.showSuccess(res.detail);
          this.fetchJob();
        } else {
          this.notify.showError(res.detail);
        }
        this.applyLoading = false;
      },
      error: (e) => {
        this.applyLoading = false;
        console.error(e);
      }
    })
  }

  selectFile(event: any): void {
    const file = event.target.files[0];

    if (file.size / 1000000 > 30) {
      this.notify.showError('The file must be less than 30 MB');
      this.selectedFile = null;  // Clear the file if it exceeds the size limit
      this.fileName = null;      // Clear the file name
    } else {
      this.selectedFile = file;  // Store the file in the component property
      this.fileName = file.name; // Get the file name
    }
  }

  async uploadFile(): Promise<null | string> {
    if (this.selectedFile && this.job) {
      this.uploadingFile = true;
      const formData = new FormData();
      formData.append('file', this.selectedFile);
  
      try {
        const res = await this.api.postFormData(`/jobs/upload-work/${this.job._id}`, formData).toPromise();
        this.uploadingFile = false;
  
        if (res.status_code === 200) {
          this.notify.showSuccess('File uploaded successfully');
          return res.detail;
        } else {
          this.notify.showError(res.detail);
          return null;
        }
      } catch (e) {
        console.error(e);
        this.uploadingFile = false;
        return null;
      }
    }
    return null;
  }

  async submitWork() {
    this.submitted = true;

    if (this.submitWorkForm.invalid) {
      return;
    }

    this.submitWorkLoading = true;

    const response = await this.uploadFile();

    const work = {
      job_id: this.job._id,
      submission: {
        link: this.submitWorkForm.value.link,
        comment: this.submitWorkForm.value.comment,
      },
      file: response || undefined // Include file only if the response is not null
    };

    this.api.put('/jobs/submit-work', JSON.parse(JSON.stringify(work))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.submitWorkLoading = false;
          this.submitted = false;
          this.notify.showSuccess(res.detail);
          this.submitWorkForm.reset();
          this.modal.dismissAll();
          this.fetchJob();
        } else {
          this.submitted = false;
          this.submitWorkLoading = false;
          this.notify.showError(res.detail);
        }
      },
      error: (e) => {
        this.submitted = false;
        this.submitWorkLoading = false;
        console.error(e);
      }
    });
  }

  downloadFile(file_name: string): void {
    // Set the loading state for the specific file
    this.downloadingFileMap[file_name] = true;
    this.api.getFile(`/jobs/download-file/${file_name}`).subscribe(
      blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = file_name;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.downloadingFileMap[file_name] = false;
      }
    )
  }

  /**
   * Detects if the data in the submissions has changed
   * @param page - The page that has been selected
   */
  onSubmissionsDataChange(event: any): void{
    this.submissionsPagingConfig.currentPage = event;
    this.fetchJob();
  }

}
