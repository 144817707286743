<div class="profile">
    <div class="container">
        <div class="d-flex justify-content-center" *ngIf="userLoading">
            <app-loading></app-loading>
        </div>
        <div class="row" *ngIf="!userLoading">
            <div class="col-md-12">
                <form [formGroup]="editProfileForm">
                    <!-- Upload image -->
                    <div class="avatar">
                        <div class="avatar-upload">
                            <div class="avatar-edit">
                                <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                                    (change)="uploadFile($event)" />
                            </div>
                            <div class="avatar-preview">
                                <div id="imagePreview" [style.backgroundImage]="'url(' + user.profile_image + ')'"></div>
                            </div>
                        </div>
                        <div class="photo">
                            <p class="text-center">Supported types: PNG, JPG, JPEG</p>
                            <p class="text-center">Max size: 5MB</p>
                        </div>
                        <div class="photo-buttons">
                            <button class="d-flex btn btn-info btn-block" (click)="fileInput.click()" [disabled]="loading"
                            *ngIf="user.profile_image == './assets/images/default_avatar.jpg'">
                                UPLOAD PICTURE
                                <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true">
                                </span>
                            </button>
                            <button class="d-flex btn btn-info btn-block" (click)="removeProfilePicture()"
                                *ngIf="user.profile_image != './assets/images/default_avatar.jpg'" [disabled]="deleteLoading">
                                REMOVE PICTURE
                                <span *ngIf="deleteLoading" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true">
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group col-md-6 mb-4">
                        <label for="title">Title</label>
                        <input type="text" class="form-control" formControlName="title"
                            [ngClass]="{ 'is-invalid': submitted && f['title'].errors }" />
                        <div *ngIf="submitted && f['title'].errors" class="invalid-feedback">
                            <div *ngIf="f['title']['errors']required">Title is required</div>
                        </div>
                    </div>
                    <div class="form-group col-md-6 mb-4">
                        <label for="Bio">Bio. </label>
                        <textarea class="form-control" formControlName="bio" rows="4" [(ngModel)]="characters"
                            maxlength="400" placeholder="Tell us a bit about yourself..." autofocus
                            [ngClass]="{ 'is-invalid': submitted && f['bio'].errors }">
                        </textarea>
                        <div class="count">
                            Count: {{characters.length}}/400
                        </div>
                        <div *ngIf="submitted && f['bio'].errors" class="invalid-feedback">
                            <div *ngIf="f['bio']['errors']required">Bio is required</div>
                        </div>
                    </div>

                    <div class="form-group services col-md-6 mb-3">
                        <h6>Add/Remove skills below (Max 3):</h6>
                        <div class="row">
                            <div class="col-md-4" *ngFor="let service of services">
                                <mat-checkbox 
                                    (click)="$event.stopPropagation()"
                                    [checked]="service.selected"
                                    [disabled]="!service.selected && selectedCount >= 3"
                                    (change)="toggleCategorySelection(service)">
                                    {{ service.title }}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6 buttons mb-5">
                        <button class="d-flex btn btn-info btn-block" (click)="update()" [disabled]="isLoading">
                            UPDATE PROFILE
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                        </button>
                    </div>
                    <div class="form-group col-md-6 mb-4">
                        <div class="resume">
                            <p class="filename" *ngIf="user.resume">Resume: {{user.resume}}</p>
                            <button class="btn btn-info mb-3" (click)="downloadResume(user.resume)" [disabled]="downloadingResume" *ngIf="user.resume">
                                DOWNLOAD RESUME
                                <span *ngIf="downloadingResume" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true">
                                </span>
                            </button>
                            <p class="filename" *ngIf="user.resume">NB: Uploading a new resume will overwrite the previous one.</p>
                            <p class="filename">Supported types: PDF.</p>
                            <p class="filename">Max size: 1MB.</p>
                            <input type="file" id="resumeUpload" accept=".pdf" #resumeUpload
                            (change)="uploadResume($event)"/>
                            <button class="d-flex btn btn-info btn-block" (click)="resumeUpload.click()" [disabled]="uploadingResume">
                                UPLOAD RESUME
                                <span *ngIf="uploadingResume" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true">
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>